import { guid } from "@/utils/other.js";
import { toolsts } from "@/api";
import ObsClient from "esdk-obs-browserjs";
//上传阿里云
export async function uploadRequests(file) {
  return new Promise((resolve, reject) => {
    const isLt5M = file.size / 1024 / 1024 < 500;
    if (!isLt5M) {
      Message.error("上传图片不能大于500M!");
      reject("上传图片不能大于500M!");
    }

    let pos = file.name.lastIndexOf(".");
    let extendName = file.name.substring(pos + 1);
    let fileNames = `backstage/${guid()}.${extendName}`;

    toolsts().then(async (res) => {
      if (res.code === 200) {
        const { bucket, endpoint, ak, sk } = res.data;
        const obsClient = new ObsClient({
          access_key_id: ak,
          secret_access_key: sk,
          server: `https://${endpoint}`,
          x_obs_acl: "public-read",
        });

        obsClient.putObject(
          {
            Bucket: bucket,
            Key: fileNames,
            Body: file,
            ACL: "public-read",
          },
          (err, result) => {
            if (err) {
              reject("华为云上传失败");
            } else {
              if (result.CommonMsg.Status == 200) {
                let restImg = `https://${bucket}.${endpoint}/${fileNames}`;
                resolve(restImg);
              }
            }
          }
        );
      } else {
        reject(res.msg);
      }
    });
  });
}
